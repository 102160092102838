import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BookmarkMenuItem from "./bookmarkMenuItem";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { configuration } from "../../helpers/config";
import { Constants } from "../../helpers/constants";

// Component to show bookmarks on Brand Page.
export default function BookmarkMenu({ menuId,menudata }) { 
  let menu = menudata?.filter((item) => item._id === menuId);
  const [transparent, setTransparent] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setTransparent(false);
      } else {
        setTransparent(true);
      }
    });
  }, []);

  return (
    <div
      className={`background-subNav fixed ${
        transparent
          ? "top-[60px] left-0 right-0 "
          : "md:bg-inverted top-[60px] right-0 left-0"
      } h-[40px] w-full md:z-[2] z-[2] transition-all duration-500 container-fluid`}
    >
      <div className="container mx-auto">
        <div className="md:hidden car_details__select">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>{Constants.bookMarkMenu.CAR_DETAILS}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "" : "rotate-180 transform"
                    } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <ul className="flex flex-col text-center md:row">
                    <BookmarkMenuItem 
                    // Bmenu={Bmenu}
                    Bmenu={menu?.[0]?.nestableArray} 
                    />
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        {/* <div className="hidden md:block">
          <ul className="flex justify-end row">
            {Bmenu?.map((bm, i) => (
              <BookmarkMenuItem
                key={i}
                id={bm.url}
                label={bm.text}
                active={bm.active}
              />
            ))}
          </ul>
        </div> */}
        <div className="hidden md:block">
          {/* <BookmarkMenuItem Bmenu={Bmenu} /> */}
          <BookmarkMenuItem Bmenu={menu?.[0]?.nestableArray}/>
        </div>
      </div>
    </div>
  );
}
