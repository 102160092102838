import Image from "next/image";
import styles from "../../styles/contactUs.module.css";

// Component component for Contact Us Page
const ContactNavigate = ({
  textContent,
  imgSrc,
  marginClass,
  Id,
  href,
  widthManage,
}) => {
  return (
    <a
      href={href}
      id={Id}
      target={"_blank"}
      rel="noreferrer"
      className={`decoration-0 max-w-[300px] contact-navigate flex items-center justify-between border-solid border-b-[2px] border-[#D9D8D9] ${marginClass}`}
    >
      {textContent && (
        <div className="contacts w-[85%] pb-[11px] ">
          <span className="uppercase font-suzukiBold text-[#001151] text-[14px] leading-[21px]"  id={Id}>
            {textContent}
          </span>
        </div>
      )}

      <button
        // href={href}
        // target={"_blank"}
        // rel="noreferrer"
        id={Id}
        className={styles.navigate_icon}
      >
        <Image src={imgSrc} width={18} height={18} alt="" />
      </button>
    </a>
  );
};

export default ContactNavigate;
