import style from "../../styles/preference.module.css";

// Common Heading Component for HU-PREFERENCE PROGRAM PAGE
const CommonHeading = ({ heading, desc }) => {
  return (
    <>
      <div className={style.common_heading}>
      <h2 className={style.common_heads} style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
         {heading}
       </h2>
        <p className={style.common_descs}>{desc}</p>
      </div>
    </>
  );
};

export default CommonHeading;
