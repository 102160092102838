// export default ReadMore;
import { useState, useRef, useEffect } from "react";
import { Constants } from "./../helpers/constants";
const ReadMore = ({ text, maxLines = 3, heading }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const pstyle = {
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const ref = useRef(null);
  const checkOverflow = () => {
    if (ref.current) {
      setShowReadMoreButton(
        ref.current.scrollHeight > ref.current.clientHeight
      );
    }
  };

  useEffect(() => {
    // Initial check for overflow
    checkOverflow();

    // Add resize event listener
    window.addEventListener("resize", checkOverflow);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <div className="overflow-hidden">
      {!heading ? (
        //this part for description
        <>
          <p
            ref={ref}
            className="topicCard__details--desc overflow-hidden"
            style={isOpen ? null : pstyle}
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {showReadMoreButton && (
            <div
              onClick={() => setIsOpen(!isOpen)}
              id='readMoreLessButton'
              className=" text-primary-blue mb-6 font-bold-400 cursor-pointer overflow-hidden"
            >
              {isOpen ? Constants?.readLess : `...${Constants?.readMore}`}
            </div>
          )}
        </>
      ) : (
        //this part for heading
        <>
          <h2 ref={ref}  style={isOpen ? {} : pstyle}>
            {text}
          </h2>

          {showReadMoreButton && (
            <div
              onClick={() => setIsOpen(!isOpen)}
              className=" text-primary-blue font-bold-400 cursor-pointer overflow-hidden"
            >
              <p>
                {" "}
                {isOpen ? Constants?.readLess : `...${Constants?.readMore}`}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ReadMore;
