import React from "react";
import Image from "next/image";
import { configuration } from "../../helpers/config";
import Link from "next/link";

const ExperienceIcon = ({
  icon,
  title,
  href,
  onClick,
  Id,
  alt_text,
  target,
}) => {
  // large icon with link mainly used in brand/ cms pages .
  return (
    <div className="icons-grid text-center" id={Id}>
      {href ? (
        <Link
          id={Id}
          href={href || "#"} // Fallback to '#' if href is undefined
          onClick={onClick}
          passHref
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
        >
          <div className="text-center my-10 inline-block cursor-pointer">
            <Image
              id={Id}
              height="40px"
              width="40px"
              className="text-center"
              src={icon}
              alt={alt_text}
            />
            <p className="font-bold mt-3 text-[16px] uppercase">{title}</p>
          </div>
        </Link>
      ) : (
        <a
          className="text-center my-10 inline-block cursor-pointer"
          id={Id}
          target={target}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          <Image
            id={Id}
            height="40px"
            width="40px"
            className="text-center"
            src={icon}
            alt={alt_text}
          />
          <p className="font-bold mt-3 text-[16px] uppercase">{title}</p>
        </a>
      )}
    </div>
  );
};

export default ExperienceIcon;
