import React, { useState } from "react";
import LinkButton from "../button";
import Image from "next/image";
import ReactPlayer from "react-player";
import { configuration } from "../../helpers/config";
import { CarPageAllSections } from "../../pages/pages/templates/all_components";
import youtubeThumbnail from "youtube-thumbnail";
import { Modal } from "react-responsive-modal";
import useIsMobile from "../../hooks/useIsMobile";
import { BsYoutube } from "react-icons/bs";
import { formatDateByCountryCode, validateUrl,redirectRoute } from "../../utils/GenUtils";
import { toggleForm } from "../../redux/ActionCreator";
import { useDispatch } from "react-redux";
import Link from "next/link";
import ReadMore from "../Readmore";

// component to render inside of topic slider components, contains modal for images and videos as well .
export default function TopicSlide({
  showHeading,
  cardClassName,
  imgSrc,
  alt_text,
  cardDate,
  cardDateEnabled,
  cardDescription,
  cardBtnText,
  cta,
  target,
  cardTitle,
  cardLink,
  form,
  Id,
  videoSrc,
  date,
  date_enabled,
  filename_pdf,
  title
}) {

  const [hide, setHide] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const mobile = useIsMobile();
  const dispatch = useDispatch();
  // validating url
  let isValidUrl = validateUrl(cardLink);
  // cardLink=redirectRoute(cardLink,"/vnm-raider","/404");

  return (
    <div
      className={`${hide && "hidden"} ${
        cardClassName ? cardClassName + " topicCard mr-[50px]" : "topicCard"
      }`}
    >
      <Modal
        onClose={() => setShowPlayer(false)}
        open={showPlayer}
        classNames={{
          modal: "topicSlide__modal",
        }}
      >
        <ReactPlayer
          onClick
          fullScreen={true}
          url={videoSrc}
          volume={1}
          width={mobile ? "90vw" : "65vw"}
          height={mobile ? "50vw" : "35vw"}
          playsinline
        />
      </Modal>
      <div className="topicCard__image" id={Id}>
        {videoSrc && videoSrc.includes("youtube.com") ? (
          <button onClick={() => setShowPlayer(true)} className="relative">
            <span className="absolute z-20 top-[50px] left-[110px]">
              <BsYoutube size={50} color={"red"} />
            </span>
            <Image
              src={youtubeThumbnail(videoSrc).medium.url}
              className=""
              width={398}
              height={221}
              alt=""
              // onError={(e) => setHide(true)}
            />
          </button>
        ) : (
          <div>
            {cta && cardLink ? (
              <div>
                <a
                  target={target}
                  className="cursor-pointer"
                  // ​href={!isValidUrl ? (CarPageAllSections.includes(cardLink.split("#")[1])?`/cars/${cardLink}`:`/pages/${cardLink}`): cardLink}
                  href={
                    !isValidUrl
                      ? CarPageAllSections.includes(cardLink.split("#")[1])
                        ? `/cars/${cardLink}`
                        : `/pages/${cardLink}`
                      : cardLink
                  }
                >
                  <div className="cursor-pointer">
                    {imgSrc ? (
                      <Image
                        src={imgSrc ? `${configuration.IMG_URL}${imgSrc}` : ""}
                        // src={imgSrc}
                        className=""
                        width={398}
                        height={221}
                        alt={alt_text}
                        onError={(e) => setHide(true)}
                      />
                    ) : !imgSrc && filename_pdf ? (
                      <div className="pdf-icon-container">
                        <img
                          src={`${configuration.IMG_URL}static_img/assets/images/pdficon.png`}
                          alt="PDF Icon"
                          className="pdf-icon"
                        />
                      </div>
                    ) : null}
                  </div>
                </a>
              </div>
            ) : (
              <div>
                {imgSrc && filename_pdf ? (
                  <a
                    href={configuration.IMG_URL + filename_pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={imgSrc ? `${configuration.IMG_URL}${imgSrc}` : ""}
                      // src={imgSrc}
                      className=""
                      width={398}
                      height={221}
                      alt={alt_text}
                      onError={(e) => setHide(true)}
                    />
                  </a>
                ) : imgSrc && !filename_pdf ? (
                  <a
                    href={configuration.IMG_URL + filename_pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{pointerEvents: "none"}}
                  >
                    <Image
                      src={imgSrc ? `${configuration.IMG_URL}${imgSrc}` : ""}
                      // src={imgSrc}
                      className=""
                      width={398}
                      height={221}
                      alt={alt_text}
                      onError={(e) => setHide(true)}
                    />
                  </a>
                ) :  !imgSrc && filename_pdf ? (
                  <div className="pdf-icon-container">
                    <a
                      href={configuration.IMG_URL + filename_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                         src={`${configuration.IMG_STATIC_URL}pdficon.png`}
                        alt="PDF Icon"
                        className="pdf-icon"
                        style={{height: "160px",width:"160px"}}
                      />
                    </a>
                  </div>
                ) : null}
              </div>
          )}
          </div>
        )}
      </div>
      <div className="topicCard__details px-[4px]">
        <div className="topicCard__details--info">
        {title && (
      <h2
        className="kids-print-heading text-[24px] h-[75px] lg:h-[74px] md:h-[74px] text-primary-blue text-section-heading mb-[10px] !h-auto"
        style={{ marginTop: "10px", marginBottom: "5px" }}
      >
        <ReadMore maxLines={2} heading={true} text={title} />
      </h2>
    )}
      {/* {cardDescription && (
        <p
          className="topicCard__details--desc"
          dangerouslySetInnerHTML={{ __html: cardDescription }}
        />
      )} */}
          {cardDateEnabled && (
            <p className="topicCard__details--date">
              {formatDateByCountryCode(cardDate)} 
            </p>
          )}
          {showHeading && (
            <h3 className="topicCard__details--title">
              <ReadMore maxLines={2} heading={true} text={cardTitle} />
              </h3>
          )}
          {date_enabled && <span>{date}</span>}
          <ReadMore text={cardDescription} />
          {/* <p
            className="topicCard__details--desc overflow-hidden min-h-[64px] max-h-[64px] unreset  line-clamp-2 p-2"
            dangerouslySetInnerHTML={{ __html: cardDescription }}
          /> */}
          {cta && cardLink && cardBtnText && (
            <LinkButton
              Id={Id}
              type={"button_dark_arrow"}
              buttontext={cardBtnText}
              target={target}
              href={
                !isValidUrl
                  ? CarPageAllSections.includes(cardLink.split("#")[1])
                    ? `/cars/${cardLink}`
                    : `/pages/${cardLink}`
                  : cardLink
              }
              classname="button_dark_arrow"
            />
          )}
          {cta && cardBtnText && !cardLink && form && (
            <div className="compareItem__CTAs !block">
              <LinkButton
                Id={Id}
                type={"button_dark_arrow_button"}
                buttontext={cardBtnText}
                onClick={() => {
                  dispatch(toggleForm(form));
                }}
                classname="button_dark_arrow fullBorder__btn"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
