import Image from "next/image";
import styles from "../../styles/faq.module.css";
import AccordionGrid from "./AccordionGrid";
import { useState } from "react";
import LinkButton from "../button";
import {configuration} from "../../helpers/config";

// accordion parent element
const Accordion = ({ newArray, heads, selectText, openText, arrowRotate }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {heads && (
        <div className="faq-page-head">
          <h2 className={styles.faq_grid_heading}>{heads}</h2>
        </div>
      )}
      <div className="faq-page-slides">
        {newArray &&
          newArray.slice(0, open ? newArray?.length : 4).map((elem, idx) => {
            return (
              <AccordionGrid
                key={elem.id}
                id={elem.id ? elem?.id : idx + 1}
                question={elem.question}
                answer={elem.answer}
                bullets
                answerTwo={elem.answerTwo}
              />
            );
          })}
      </div>
      <div className={styles.select_wrap}>
        <div onClick={() => setOpen(!open)} className={styles.faq_page_select}>
          {/* <select className={styles.select_tag} name="" id="">
            <option value="">{selectText}</option>
          </select> */}
          {/* <p className={`${styles.select_tag} cursor-pointer`}>{open ? "Mutass kevesebbet" : selectText }</p>
          <div className={styles.faq_dropdown}>
            <Image
              src={open ? `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionUp.svg` : `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionArrow.svg`}
              width={15}
              height={15}
              alt=""
              className={`align-middle ${arrowRotate}`}
            />
          </div> */}

          {newArray?.length > 4 && (
            <div className="compareItem__CTAs">
              <LinkButton
                type={"button_slide_arrow_button"}
                // buttontext={open ? "Mutass kevesebbet" : selectText}
                buttontext={open ? openText : selectText}
                classname="button_dark_arrows group fullBorder__btn"
                // btnWidth = "min-w-[280px]"
                iconImage={
                  open
                    ? `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionUp.svg`
                    : `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionArrow.svg`
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Accordion;
