import React,{useState,useEffect} from 'react'
import styles from "../../styles/fleet.module.css";
import FleetPageSwipe from "./FleetPageSwipe"
import { configuration } from "../../helpers/config";
import axios from "axios";

const index = ({item}) => {

    const [modelList,setmodelList]= useState()
    const [getmodedesclaimer,setgetmodedesclaimer] = useState()

    

useEffect(()=>{
    if(modelList){
        item?.fleetmodels?.map((fleet)=>{
            if(fleet.modelcode===modelList[0]?.model_code){
                 setgetmodedesclaimer(fleet)
            }
        })
    }

},[modelList])

    async function getModel() {
        try {
            const cars = await axios.get(`${configuration.APP_URL_PRODUCTS}model/`);
    
            const fleetmodel = item; // Assuming `item` contains `fleetmodels`
            const allmodel = cars.data; // API response data
    
            // Arrays to store matching objects and all objects
            const matchedModels = [];
            const allObjects = allmodel?.data || [];
    
            // Iterate and find matches
            fleetmodel?.fleetmodels?.forEach((ele) => {
                allObjects?.forEach((e) => {
                    if (ele.modelid === e._id) {
                        matchedModels.push(e); // Add to the matched array
                    }
                });
            });
              // Sort the matched models alphabetically by `model_name` (or another property)
              const sortedModels = matchedModels.sort((a, b) => 
                a.model_name.localeCompare(b.model_name) // Replace `model_name` with the desired property
            );
            // Log or use the results
            // console.log("Matched Models:", matchedModels);
            // console.log("All Models:", allObjects);
    
            // Set the results to state or return as needed
            setmodelList(sortedModels); // Assuming you have `setModelList` for matched models
            setAllModels(allObjects); // Assuming you want to save all models separately
    
            return { matchedModels, allObjects }; // Return both arrays if needed
        } catch (error) {
            console.error("Error fetching models:", error);
        }
    }

    
    // Call the function
    useEffect(() => {
        getModel(); // Call the function
    }, [item]); 
    
  return (
    <div className={styles.fleet_priceList} id="fleet_pricing">
    <div className="container">
      <div className="fleetpriceList-wrapper">
        <FleetPageSwipe
          head="Flotta árlista"
          desc="Modelljeink alapfelszereltségéről és a választható extrákról az alábbi táblázatból juthat bővebb információhoz."
          fleetPricings={modelList}
          item ={item}
          getmodedesclaimer={getmodedesclaimer}
          setgetmodedesclaimer={setgetmodedesclaimer}
        ></FleetPageSwipe>

        
      </div>
    </div>
     {/* New Section */}
    {getmodedesclaimer?.desclaimer1 && <div className="container">
  <div className="bg-[#EFF5FF] p-6 mt-20 rounded-xl">
  <p
      className="text-[16px] text-[#001151]"
      dangerouslySetInnerHTML={{ __html: getmodedesclaimer?.desclaimer1 }}
    ></p>
    
  </div>
  </div>}
  {getmodedesclaimer?.desclaimer2 && <div className="container">
  <div className="bg-[#EFF5FF] p-6 mt-2 rounded-xl">
  <p
      className="text-[16px] text-[#001151]"
      dangerouslySetInnerHTML={{ __html: getmodedesclaimer?.desclaimer2 }}
    ></p>
    
  </div>
  </div>}
  </div>
  )
}

export default index