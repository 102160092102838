import useIsMobile from "../../../hooks/useIsMobile";
import ErrorBanner from "../../../components/ErrorPage/ErrorBanner";
import "react-multi-carousel/lib/styles.css";
import Head from "next/head";
import CommonHomeCard from "../../../components/commonHomeCard";
import { configuration } from "../../../helpers/config";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import FinanceBanner from "../../../components/FinanceBanner";
import Carousel from "react-multi-carousel";
import TopicSlide from "../../../components/topics/topicSlide";
import BrandCard from "../../../components/brandcard/brand-card";
import styles from "../../../styles/faq.module.css";
import Accordion from "../../../components/accordion";
import style from "../../../styles/news_blogs.module.css";
import styless from "../../../styles/preference.module.css";
import Image from "next/image";
import LinkButton from "../../../components/button";
import Link from "next/link";
import CommonHeading from "../../../components/preference-program/CommonHeading";
import AdvantageGrid from "../../../components/preference-program/AdvantageGrid";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../../redux/ActionCreator";
import { formNames } from "../../../components/forms/Validation";
import ReadMore from "../../../components/Readmore";
import { Constants } from "../../../helpers/constants";
// import DrivingSupport from "../../../components/DrivingSupportSystems/DrivingSupport";
// import SuzukiHybrid from "../../../components/SuzukiHybrid/SuzukiHybrid";
import BookmarkMenu from "../../../components/brandmenu.js/bookmarkMenu";
// import Multitabfour from "../../../components/MultiTabFour/Multitabfour";
// import Multitabthree from "../../../components/MultiTabThree/Multitabthree";
import MultitabCommmon from "../../../components/MultiTabCommon/MultitabCommon";
import EmbeddedForm from "../../../components/EmbeddedForm";
import ExperienceIcon from "../../../components/experience-icon";
import FleetPage from "../../../components/FleetPage/index";
// import style from "../../../styles/preference.module.css"
import TestimonialSlider from "../../../components/testimonial/TestimonialSlider";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRouter } from 'next/router';
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar]);
import "swiper/css";
import { pdfjs } from "react-pdf";
import SwiperSlider from "../../../components/SwiperSlider"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const CarPageAllSections = [
  "top",
  "exterior",
  "interior",
  "safety",
  "performance",
  "comfortable_equipment",
  "see_details",
  "pricing",
];

export default function GenericTemplate(props) {
  const { data: propData, error } = props;
  const [formsData, setFormsData] = useState([]);
  // const [newData, setNewData] = useState({});
  let newData = {};
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const dispatch = useDispatch();
  const mobile = useIsMobile();
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  const [themeData, setThemeData] = useState(null);
  const reduxData = useSelector(({ app }) => app);
  const swiperRef = useRef(null);

  const [isBeginning, setIsBeginning] = useState(true); // Tracks if the swiper is at the beginning
  const [isEnd, setIsEnd] = useState(false); // Tracks if the swiper is at the end

  const swiperRef1 = useRef(null);
  const [isBeginning1, setIsBeginning1] = useState(true); // Tracks if the swiper is at the beginning
  const [isEnd1, setIsEnd1] = useState(false); // Tracks if the swiper is at the end

  const router = useRouter();
   const [user_email, setEmail] = useState('');
   const [user_phone, setPhone] = useState('');
 
   useEffect(() => {
     if (router.isReady) {
       const { email, phone } = router.query;
       if (email && phone) {
         setEmail(email);
         setPhone(phone);
       
       }
     }
	   }, [router.isReady, router.query]);

  // Handle the swiper's slide change event
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.slideNext();
  };

  const handleSlideChange1 = () => {
    if (swiperRef1.current) {
      setIsBeginning1(swiperRef1.current.isBeginning);
      setIsEnd1(swiperRef1.current.isEnd);
    }
  };

  const handlePrev1 = () => {
    if (swiperRef1.current) swiperRef1.current.slidePrev();
  };

  const handleNext1 = () => {
    if (swiperRef1.current) swiperRef1.current.slideNext();
    };
  
  useEffect(() => {
    if (!configuration.API_LOB) return; 
    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/forms?lob=${configuration.API_LOB}&dealer_code=${reduxData.dealerCode}`
          );
          setFormsData(res.data[0]?.forms);
        } catch (e) {}
      })();
    }
  }, [reduxData.dealerCode]);

  useEffect(() => {
    if (!configuration.API_LOB) return; 
    if (reduxData.dealerCode) {
      getThemeData();
    }
  }, [reduxData.dealerCode]);

  const getThemeData = async () => {
    const { data } = await axios.get(
      `${configuration.APP_URL_CONTENTS}/settings?lob=${configuration.API_LOB}&dealer_code=${reduxData.dealerCode}`
    );

    if (data?.length > 0) {
      setThemeData(data[0]?.theme_settings?.find((x) => x.applied === true));
    }
  };

  const titleThemeState = {
    titleColor: themeData?.cms_page_title_color,
    titleFontSize: themeData?.cms_page_title_font_size,
  };
  const [titleTheme, setTitleTheme] = useState(titleThemeState);

  useEffect(() => {
    setTitleTheme({
      ...titleTheme,
      titleColor: themeData?.cms_page_title_color,
      titleFontSize: themeData?.cms_page_title_font_size,
    });
  }, [themeData]);

  // useEffect(() => {
  const sortedData = [];
  const sortedCtaLayout = [];

  // Iterate through the properties of the JSON object
  for (const key in propData) {
    // if (propData[key]?.hasOwnProperty("order_index")) {
    //   // Include the object in the sortedData array
    //   sortedData.push({ key, data: propData[key] });
    // }
    if (Array.isArray(propData[key])) {
      propData[key].forEach(function (o) {
        if (o.hasOwnProperty("order_index")) {
          sortedData.push({ key, data: o });
        }
      });
      // for (const nkey in propData[key]) {
      //     if (propData[key][nkey].hasOwnProperty("order_index")){
      //         sortedData.push(propData[key][nkey])
      //     }
      // }
    } else if (propData[key]?.hasOwnProperty("order_index")) {
      // Include the object in the sortedData array
      sortedData.push({ key, data: propData[key] });
    }
  }

  for (const key in propData?.ctaLayout?.[0]?.slides) {
    if (propData?.ctaLayout[0]?.slides[key].hasOwnProperty("order_index")) {
      sortedCtaLayout.push({
        key,
        data: propData?.ctaLayout[0]?.slides[key],
      });
    }
  }

  for (const i in propData?.multiTabs) {
    if (propData?.multiTabs[i].hasOwnProperty("order_index")) {
      let key = "multitab";
      sortedData.push({ key, data: propData?.multiTabs[i] });
    }
  }
  // for (const i in propData?.embeddedForm) {
  //     if (propData?.embeddedForm[i].hasOwnProperty("order_index")) {
  //         let key = "embeddedForm";
  //         sortedData.push({ key, data: propData?.embeddedForm[i] });
  //     }
  // }

  // Sort the array based on the order_index
  sortedData.sort((a, b) => a.data.order_index - b.data.order_index);
  sortedCtaLayout.sort((a, b) => a.data.order_index - b.data.order_index);

  // setNewData({
  //     ...propData,
  //     movableData: sortedData,
  //     movableCtalayout: sortedCtaLayout,
  // });
  newData = {
    ...propData,
    movableData: sortedData,
    movableCtalayout: sortedCtaLayout,
  };
  // }, []);
  useEffect(() => {
    if (mobile) {
      if (newData?.images?.mob_banner || newData.mobile_video) {
        setIsBannerVisible(true);
      } else {
        setIsBannerVisible(false);
      }
    } else {
      if (newData?.images?.desk_banner || newData.video) {
        setIsBannerVisible(true);
      } else {
        setIsBannerVisible(false);
      }
    }
  }, [newData, mobile]);

  useEffect(() => {
    setTimeout(() => {
      let urlhash = window.location.hash?.slice(1);
      document.getElementById(urlhash)?.scrollIntoView();
    }, 100);
  }, []);

  const custServices = {
    heads: Constants.faqs.FREQUENTLY_ASKED_QUESTION,
    selectText: Constants.faqs.FURTHER_QUESTIONS,
    openText: Constants.faqs.SHOW_LESS,
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
    },
  };

  const responsive1 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
    },
  };

  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
    },
  };

  const responsive3 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      // partialVisibilityGutter: 60,
    },
  };

  const responsive4 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      // partialVisibilityGutter: 60,
    },
  };

  const PDFThumbnail = ({ pdfUrl }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      const renderPDFThumbnail = async () => {
        try {
          const loadingTask = pdfjs.getDocument(pdfUrl);
          const pdf = await loadingTask.promise;
          const page = await pdf.getPage(1); // Fetch the first page

          const canvas = canvasRef.current;
          const context = canvas.getContext("2d");
          const viewport = page.getViewport({ scale: 1 }); // Adjust scale as needed

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          await page.render({
            canvasContext: context,
            viewport,
          }).promise;
        } catch (error) {
          console.error("Error rendering PDF thumbnail:", error);
        }
      };

      renderPDFThumbnail();
    }, [pdfUrl]);

    return <canvas ref={canvasRef} className="pdf-thumbnail" />;
  };

  if (error) {
    return (
      <ErrorBanner
        image={`${configuration.IMG_STATIC_URL}errorBanner.png`}
        imageMob={`${configuration.IMG_STATIC_URL}errorMob.png`}
        error="404"
        desc="Sorry, the page you’re looking for doesn’t exist"
        btn="Go Back"
      />
    );
  }

  
  const returnComponent = (data) => {
    switch (data.key) {
      case "oneslide":
        return (
          <>
            {data?.data?.slides && data?.data?.slides?.length > 0 && (
              <div
                id="oneslide"
                className="single-slide md:mb-[150px] mb-[60px]"
              >
                <div className="container">
                  <h2 className="uppercase font-suzukiBold text-primary-blue text-heading mb-[50px]">
                    {data?.data?.title}
                  </h2>
                  {data?.data?.slides?.length > 0 && (
                    <Swiper
                      slidesPerView={1}
                      scrollbar={{ draggable: true }} // Enable a draggable scrollbar
                      freeMode={true} // Enable free scrolling
                      breakpoints={{
                        0: { slidesPerView: 1 }, // Mobile: 1 slide (0 to 639px)
                        640: { slidesPerView: 1 },
                        1024: { slidesPerView: 1 }, // Medium devices: 3 slides (768 to 1023px)
                        3000: { slidesPerView: 1 }, // Large devices: 4 slides (1024px and above)
                      }}
                    >
                      {data?.data?.slides?.map((item, idx) => (
                        <SwiperSlide key={idx} className="swiper-model">
                          <CommonHomeCard
                            Id={"OneSlide" + item?.title?.replace(/\s+/g, "")}
                            key={idx}
                            imgSrc={
                              item?.image
                                ? configuration.IMG_URL + item?.image
                                : ""
                            }
                            imgMobileSrc={
                              item?.image_mob
                                ? configuration.IMG_URL + item?.image_mob
                                : ""
                            }
                            youtube_url={item?.youtube_url}
                            alt_text={item?.alt_text}
                            formsData={formsData}
                            form={item?.form_id}
                            imgHeight={738}
                            videoHeight={550}
                            videoWidth={1920}
                            imgWidth={1920}
                            contentClassName={` md:max-w-[80%] `}
                            headClassName={`min-h-[75px] lg:min-h-[75px] md:min-h-[75px]`}
                            descriptClass={`h-[64px] lg:h-[64px] md:h-[64px]`}
                            imgMobileHeight={564}
                            imgMobileWidth={768}
                            cardTitle={item?.title}
                            cardDescription={item?.desc}
                            cardBtnText={item?.cta_text}
                            cardLink={item?.cta_link}
                            targets={item?.target === "self" ? "" : "_blank"}
                            btnType={"button_dark_arrow"}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              </div>
            )}
          </>
        );
        case "twoslide":
          return (
            <>
              {data?.data?.slides && data?.data?.slides?.length > 0 && (
                <div
                  id="twoslide"
                  className="container-fluid mt-[95px] md:mt-[100px] md:mb-[150px] mb-[50px]"
                >
                  <div className="container">
                    <div className="cms-page-news">
                    <h2 className=" text-primary-blue font-suzukiBold mb-[30px] text-heading">
                        {data?.data?.title}
                    </h2>
                      <div className="cms-content-wrapper">
                        {data?.data?.slides?.length > 0 && (
                          <Swiper
                            slidesPerView={2}
                            scrollbar={{ draggable: true }} // Enable a draggable scrollbar
                            freeMode={true} // Enable free scrolling
                            breakpoints={{
                              0: { slidesPerView: 1 }, // Mobile: 1 slide (0 to 639px)
                              640: { slidesPerView: 2 },
                              1024: { slidesPerView: 2 }, // Medium devices: 3 slides (768 to 1023px)
                              3000: { slidesPerView: 5 }, // Large devices: 4 slides (1024px and above)
                            }}
                          >
                            {data?.data?.slides?.map((item, index) => {
                              const youtubeEmbed = item.youtube_url
                                ? `https://www.youtube.com/watch?v=${
                                    item.youtube_url.split("v=")[1]
                                  }`
                                : null;
                              return (
                                <SwiperSlide key={index} className="swiper-model">
                                  <div className={style.blog_grid} key={index}>
                                  {item.cta_link ? (
                                    <div>
                                      <a
                                      target={
                                        item.target ===
                                        "self"
                                            ? ""
                                            : "_blank"
}
                                        className="cursor-pointer"
                                        href={
                                          CarPageAllSections.includes(
                                            item.cta_link.split("#")[1]
                                          )
                                            ? `/cars/${item.cta_link}`
                                            : item?.cta_link
                                        }
                                      >
                                    <div
                                          className={`cursor-pointer ${style.blog_grid_img}`}
                                      id={
                                        "TwoSlide" +
                                        item?.title?.replace(/\s+/g, "")
                                      }
                                    >
                                      {youtubeEmbed ? (
                                        <div
                                          className="video-container rounded-[5px]"
                                          style={{
                                            borderRadius: "0.375rem",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <ReactPlayer
                                            url={youtubeEmbed} // Directly use the YouTube URL
                                            playing={false}
                                            controls={true}
                                            loop={true} // Loop the video
                                            volume={0} // Mute the video
                                            height={mobile ? 564 : 472} // Height adjusted based on mobile/tablet
                                            width={mobile ? 768 : 850} // Shared width
                                            playsinline
                                            className="rounded-[5px]"
                                          />
                                        </div>
                                      ) : (
                                        <Image
                                          className="rounded-[5px]"
                                          src={
                                            mobile
                                                  ? item.image_mob
                                              ? configuration.IMG_URL +
                                                item.image_mob
                                                    : ""
                                                  : item.image
                                                  ? configuration.IMG_URL +
                                                    item.image
                                                  : ""
                                          }
                                          alt={item.alt_text}
                                          width={mobile ? 768 : 850}
                                          height={mobile ? 564 : 625}
                                              style={{
                                                borderRadius: "0.375rem",
                                              }}
                                        />
                                      )}
                                    </div>
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        className={style.blog_grid_img}
                                        id={
                                          "TwoSlide" +
                                          item?.title?.replace(/\s+/g, "")
                                        }
                                      >
                                         {youtubeEmbed ? (
                                            <div
                                              className="video-container rounded-[5px]"
                                              style={{
                                                borderRadius: "0.375rem",
                                                overflow: "hidden",
                                              }}
                                            >
                                              <ReactPlayer
                                                url={youtubeEmbed} // Directly use the YouTube URL
                                                playing={false}
                                                controls={true}
                                                loop={true} // Loop the video
                                                volume={0} // Mute the video
                                                height={mobile ? 564 : 472} // Height adjusted based on mobile/tablet
                                                width={mobile ? 768 : 850} // Shared width
                                                playsinline
                                                className="rounded-[5px]"
                                              />
                                            </div>
                                          ) : (
                                        <Image
                                          className="rounded-[5px]"
                                          src={
                                            mobile
                                              ? item.image_mob
                                                ? configuration.IMG_URL +
                                                  item.image_mob
                                                : ""
                                              : item.image
                                              ? configuration.IMG_URL +
                                                item.image
                                              : ""
                                          }
                                          alt={data.alt}
                                          width={mobile ? 768 : 850}
                                          height={mobile ? 564 : 625}
                                        />
                                          )}
                                      </div>
                                    </div>
                                  )}
                                    <div className="news-blogs-contents mb-[50px] md:mb-0">
                                      <div className="blog-content mb-[33px]">
                                      <div>
                                        <ReadMore
                                          maxLines={2}
                                          heading={true}
                                          text={item.title}
                                        />
                                      </div>
                                      <div style={{ overflow: "auto" }}>
                                        <ReadMore text={item.desc} />
                                      </div>
                                      {/* <p
                                          className="font-suzukiRegular overflow-hidden mb-0  md:mb-[20px] text-default unreset line-clamp-2 "
                                          dangerouslySetInnerHTML={{
                                            __html: item.desc,
                                          }}
                                    ></p> */}
                                      </div>
  
                                      <div className={style.blog_btn}>
                                        {item?.cta_link && item?.cta_text ? (
                                          <LinkButton
                                            Id={
                                              "TwoSlide" +
                                              item?.title?.replace(/\s+/g, "")
                                            }
                                            type={"button_dark_arrow"}
                                            buttontext={item.cta_text}
                                            target={
                                              item.target === "self"
                                                ? ""
                                                : "_blank"
                                            }
                                            href={
                                              youtubeEmbed
                                                ? youtubeEmbed // Use the YouTube URL if it exists
                                                : CarPageAllSections.includes(
                                                    item.cta_link?.split("#")[1]
                                                  )
                                                ? `/cars/${item.cta_link}`
                                                : item?.cta_link
                                            }
                                          />
                                        ) : (
                                          item?.form_id &&
                                          item?.cta_text && (
                                            <div className="compareItem__CTAs !block">
                                              <LinkButton
                                                Id={
                                                  "TwoSlide" +
                                                  item?.title?.replace(/\s+/g, "") // .replace(/\s+/g, "") is used to remove white spaces from the id
                                                }
                                                type={"button_dark_arrow_button"}
                                                target={
                                                  item.target === "self"
                                                    ? ""
                                                    : "_blank"
                                                }
                                                className={
                                                  "button_dark_arrow fullBorder__btn"
                                                }
                                                buttontext={item.cta_text}
                                                onClick={() =>
                                                  dispatch(
                                                    toggleForm(item.form_id)
                                                  )
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
      case "threeslide":
        return (
          <>
            {data?.data?.slides && data?.data?.slides?.length > 0 && (
              <div
                id="threeslide"
                className="container-fluid three-col-section md:mb-[100px]"
              >
                <div className="container">
                  <h2 className="uppercase font-suzukiBold text-primary-blue text-heading mb-[50px]">
                    {data?.data?.title}
                  </h2>

                  {data?.data?.slides?.length > 0 && (
                    <Swiper
                    onSwiper={(swiper) => {
                      swiperRef1.current = swiper;
                      handleSlideChange1(); // Initial check for arrow visibility
                    }}
                    onSlideChange={handleSlideChange1}
                      slidesPerView={3}
                      scrollbar={{ draggable: true }} // Enable a draggable scrollbar
                      freeMode={true} // Enable free scrolling
                      breakpoints={{
                        0: { slidesPerView: 1 }, // Mobile: 1 slide (0 to 639px)
                        640: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 }, // Medium devices: 3 slides (768 to 1023px)
                        3000: { slidesPerView: 5 }, // Large devices: 4 slides (1024px and above)
                      }}
                    >
                      {data?.data?.slides?.map((item, i) => (
                        <SwiperSlide key={i} className="swiper-model">
                          <CommonHomeCard
                            Id={"ThreeSlide" + item?.title?.replace(/\s+/g, "")} // .replace(/\s+/g, "") is used to remove white spaces from the id
                            key={i}
                            imgSrc={
                              item.image
                                ? configuration.IMG_URL + item.image
                                : ""
                            }
                            imgMobileSrc={
                              item.image_mob
                                ? configuration.IMG_URL + item.image_mob
                                : ""
                            }
                            youtube_url={item?.youtube_url}
                            alt_text={item?.alt_text}
                            imgHeight={302}
                            imgWidth={544}
                            cardClassName={
                              "mb-[50px] md:mb-0 mr-[40px] threeColumnRadius "
                            }
                            contentClassName={` md:max-w-[80%] `}
                            headClassName={`${
                              item?.column === 1 && "text-[#000]"
                            } ${
                              item?.column === 3 &&
                              "min-h-[75px] lg:min-h-[75px] md:min-h-[75px] "
                            }`}
                            modifyDesc={`h-[64px] lg:h-[64px] md:h-[64px] md:mb-[20px]`}
                            modifyHead={
                              "min-h-[35px] lg:min-h-[35px] md:min-h-[35px]"
                            }
                            descriptClass={`h-[64px] lg:h-[64px] md:h-[64px]`}
                            imgMobileHeight={426}
                            imgMobileWidth={768}
                            cardTitle={item.title}
                            cardDescription={item.desc}
                            cardBtnText={item.cta_text}
                            cardLink={item.cta_link}
                            btnType={"button_dark_arrow"}
                            formsData={formsData}
                            // target={item.target === "self" ? "" : "_blank" }
                            targets={item.target === "self" ? "" : "_blank"}
                            form={item?.form_id}
                          />
                        </SwiperSlide>
                      ))}
                      {!isBeginning1 && (
                        <button
                        className="swiper-button-prev"
                        style={{
                          left: mobile ? "0px" : "-50px",
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: 2,
                          cursor: "pointer",
                          borderRadius: "50%",
                          fontSize: mobile ? "18px" : "24px",
                        }}
                        onClick={handlePrev1}
                      >
                        ❮
                      </button>
                      )}
                      {!isEnd1 && (
                        <button
                          className="swiper-button-next"
                          style={{
                            right: mobile ? "-5px" : "-10px",
                            position: "absolute",
                            top: "50%",
                            right:"2%",
                            transform: "translateY(-50%)",
                            zIndex: 10,
                            cursor: "pointer",
                            borderRadius: "50%",
                            fontSize: mobile ? "18px" : "24px",
                          }}
                          onClick={handleNext1}
                        >
                          ❯
                        </button>
                      )}
                    </Swiper>
                  )}
                </div>
              </div>
            )}
          </>
        );
      case "leftslide":
        return (
          <>
            {data?.data?.slides && data?.data?.slides?.length > 0 && (
              <div id="leftslide">
                <BrandCard
                  Id="LeftSlide"
                  data={data?.data?.slides?.map((item) => {
                    return {
                      ...item,
                      image_mobile: item.image_mob,
                      image_desktop: item.image,
                      youtubeurl: item.youtube_url,
                      imageAlt: item.alt_text,
                    };
                  })}
                  bookMarkId={"template-card01"}
                  imageWidth={1288}
                  imageHeight={956}
                  videoWidth={"100%"}
                  videoHeight={850}
                  imageWidthMobile={768}
                  imageHeightMobile={564}
                  target={
                    data?.data?.slides[0].target === "self" ? "_self" : "_blank"
                  }
                />
              </div>
            )}
          </>
        );
      case "rightslide":
        return (
          <>
            {data?.data?.slides && data?.data?.slides?.length > 0 && (
              <div id="rightslide">
                <BrandCard
                  Id="RightSlide"
                  data={data?.data?.slides?.map((item) => {
                    return {
                      ...item,
                      image_mobile: item.image_mob,
                      image_desktop: item.image,
                      youtubeurl: item.youtube_url,
                      imageAlt: item.alt_text,
                    };
                  })}
                  bookMarkId={"template-card02"}
                  classname={"reverseOrder"}
                  cardRightPadding="md:pr-[55px] lg:pr-[55px] xl:pr-[55px]"
                  imageWidth={1288}
                  imageHeight={956}
                  videoWidth={1010}
                  videoHeight={850}
                  imageWidthMobile={768}
                  imageHeightMobile={564}
                  target={
                    data?.data?.slides[0].target === "self" ? "_self" : "_blank"
                  }
                />
              </div>
            )}
          </>
        );
      case "fourslide":
        return (
          <>
            {data?.data?.slides && data?.data?.slides?.length > 0 && (
              <div
                id="fourslide"
                className="container-fluid four-col-section md:mb-[150px] mb-[100px]"
              >
                <div className="container">
                  <h5 className="topics__container--heading">
                    {data?.data?.title}
                  </h5>
                  {data?.data?.slides?.length > 0 && (
                    // <Carousel
                    //     responsive={responsive}
                    //     autoPlay={false}
                    //     removeArrowOnDeviceType={[
                    //         "desktop",
                    //         "tablet",
                    //         "mobile",
                    //     ]}
                    //     partialVisible={true}
                    // >
                    <SwiperSlider
                      data={data?.data?.slides}
                      slidesPerView={4}
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        640: { slidesPerView: 2 },
                        1024: { slidesPerView: 4 },
                        3000: { slidesPerView: 5 },
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        );
      case "preferenceslide":
        return (
          <>
            {data?.data?.slides?.length > 0 && (
              <div className={styless.preference_working} id="cms-prefer">
                <div id="preferences" className="container">
                  <CommonHeading
                    heading={data?.data?.title}
                    desc={data?.data?.description}
                  />
                  <div className={styless.preference_working_wrapper}>
                    {data?.data?.slides?.length > 0 && (
                      <>
                        {data?.data?.slides?.map((elem, index) => {
                          return (
                            <AdvantageGrid
                              Id={
                                "Preference" + elem.title?.replace(/\s+/g, "") // .replace(/\s+/g, "") is used to remove white spaces from the id
                              }
                              key={index}
                              hideImage
                              count={index + 1}
                              image={
                                mobile
                                  ? elem?.image_mob
                                    ? configuration.IMG_URL + elem?.image_mob
                                    : ""
                                  : elem?.image
                                  ? configuration.IMG_URL + elem?.image
                                  : ""
                              }
                              heading={elem?.title}
                              desc={elem?.desc}
                              widthManage="md:h-full"
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case "multiTab":
        return (
          <>
            {newData?.multiTab?.tabsData &&
              Object.keys(newData?.multiTab?.tabsData).length > 0 && (
                <MultitabCommmon
                  data={newData?.multiTab}
                  tempid={"multitab-1"}
                />
              )}
          </>
        );

      case "suzukiHybrid":
        return (
          <>
            {newData?.suzukiHybrid?.tabsData &&
              Object.keys(newData?.suzukiHybrid?.tabsData).length > 0 && (
                <MultitabCommmon
                  data={newData?.suzukiHybrid}
                  tempid={"multitab-2"}
                />
              )}
          </>
        );

      case "MultiTabThree":
        return (
          <>
            {newData?.MultiTabThree?.tabsData &&
              Object.keys(newData?.MultiTabThree?.tabsData).length > 0 && (
                <MultitabCommmon
                  data={newData?.MultiTabThree}
                  tempid={"multitab-3"}
                />
              )}
          </>
        );

      case "MultiTabFour":
        return (
          <>
            {newData?.MultiTabFour?.tabsData &&
              Object.keys(newData?.MultiTabFour?.tabsData).length > 0 && (
                <MultitabCommmon
                  data={newData?.MultiTabFour}
                  tempid={"multitab-4"}
                />
              )}
          </>
        );
        
      case "contactUsSlide":
        return (
          <>
            {data?.data?.slides && data?.data?.slides?.length > 0 && (
              <div
                id="contactUsSlide"
                className="container-fluid three-col-section md:mb-[100px]"
              >
                <div className="container">
                  <h2 className="uppercase font-suzukiBold text-primary-blue text-heading mb-[50px]">
                    {data?.data?.title}
                  </h2>

                  {data?.data?.slides?.length > 0 && (
                    <Swiper
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                      handleSlideChange(); // Initial check for arrow visibility
                    }}
                    onSlideChange={handleSlideChange}
                    slidesPerView={4}
                    scrollbar={{ draggable: true }} // Enable a draggable scrollbar
                    freeMode={true} // Enable free scrolling
                    breakpoints={{
                      0: { slidesPerView: 1 }, // Mobile: 1 slide (0 to 639px)
                      640: { slidesPerView: 2 },
                      1024: { slidesPerView: 4 }, // Medium devices: 3 slides (768 to 1023px)
                      3000: { slidesPerView: 5 }, // Large devices: 4 slides (1024px and above)
                    }}
                  >
                      {data?.data?.slides?.map((item, i) => (
                        <>
                        <SwiperSlide key={i} className="swiper-model">
                        <CommonHomeCard
                          key={i}
                          Id={
                            "AllPagesContact" + item?.title?.replace(/\s+/g, "") // .replace(/\s+/g, "") is used to remove white spaces from the id
                          }
                          // imgSrc={configuration.IMG_URL + item.image}
                          // imgMobileSrc={configuration.IMG_URL + item.image_mob}
                          // imgHeight={302}
                          imgWidth={544}
                          cardClassName={
                            "mb-[50px] md:mb-0 mr-[40px] threeColumnRadius "
                          }
                          contentClassName={` md:max-w-[80%] `}
                          headClassName={`${
                            item?.column === 1 && "text-[#000]"
                          } ${
                            item?.column === 3 &&
                            "min-h-[75px] lg:min-h-[75px] md:min-h-[75px] "
                          }`}
                          modifyDesc={`h-[64px] lg:h-[64px] md:h-[64px] md:mb-[20px]`}
                          modifyHead={
                            "min-h-[35px] lg:min-h-[35px] md:min-h-[35px]"
                          }
                          descriptClass={`h-[64px] lg:h-[64px] md:h-[64px]`}
                          // imgMobileHeight={426}
                          // imgMobileWidth={768}
                          dial_toggle={item?.dial_toggle}
                          cta_dial1={item?.cta_dial1}
                          cta_dial2={item?.cta_dial2}
                          cardTitle={item.title}
                          cardDescription={item.desc}
                          cardBtnText={item.cta_text}
                          cardLink={item.cta_link}
                          btnType={"button_dark_arrow"}
                          formsData={formsData}
                          targets={
                            item.target ===
                            "self"
                                ? ""
                                : "_blank"
                        }
                          form={item?.form_id}
                        />
                        </SwiperSlide>
                        </>
                      ))}
                      {!isBeginning && (
                        <button
                        className="swiper-button-prev"
                        style={{
                          left: mobile ? "0px" : "-50px",
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: 2,
                          cursor: "pointer",
                          borderRadius: "50%",
                          fontSize: mobile ? "18px" : "24px",
                        }}
                        onClick={handlePrev}
                      >
                        ❮
                      </button>
                      )}
                      {!isEnd && (
                        <button
                          className="swiper-button-next"
                          style={{
                            right: mobile ? "-5px" : "-10px",
                            position: "absolute",
                            top: "50%",
                            right:"2%",
                            transform: "translateY(-50%)",
                            zIndex: 10,
                            cursor: "pointer",
                            borderRadius: "50%",
                            fontSize: mobile ? "18px" : "24px",
                          }}
                          onClick={handleNext}
                        >
                          ❯
                        </button>
                      )}
                    </Swiper>
                  )}
                </div>
              </div>
            )}
          </>
        );
      case "ctaLayout":
        return (
          <>
            <div
              className={`${
                data.ctaLayout
                  ? "container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]"
                  : "container-fluid"
              }`}
              id="technology_request"
            >
              <div className="container">
                <h2 className={styless.preference_request_head}>
                  {newData?.ctaLayout[0]?.title}
                </h2>
                <div className={styless.offer_grid}>
                  {newData?.movableCtalayout?.map((item) => {
                    return (
                      <ExperienceIcon
                        Id={
                          "CtaLayout" + item?.data?.title?.replace(/\s+/g, "") // .replace(/\s+/g, "") is used to remove white spaces from the id
                        }
                        icon={
                          item?.data?.image
                            ? `${configuration.IMG_URL}${item?.data?.image}`
                            : ""
                        }
                        alt_text={item?.data?.alt_text}
                        title={item?.data?.title}
                        onClick={() => {
                          if (item?.data?.form_id) {
                            dispatch(toggleForm(item?.data?.form_id));
                          } else if (item?.data?.cta_link) {
                            window.location.href = item?.data?.cta_link;
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );

      case "testimonials":
        return (
          <>
            <div className="container">
             {data?.data.items?.length > 0 && <h2
                className={styless.preference_request_head}
                style={{ marginBottom: "50px" }}
              >
                {data?.data?.title}
              </h2>}
            </div>
            <TestimonialSlider
              data={data?.data.items
                .sort((a, b) => a.order_index - b.order_index)
                .filter((item) => item?.update_status)}
              showHeading={false}
              pageType="all_components"
            />
          </>
        );

      case "fleet":
        return (
          <>
            <div className="container">
              {/* <h1
                  className={styless.preference_request_head}
                  style={{ marginBottom: "50px" }}
                >
                   {console.log(data,"dttt")}
                   {data?.data?.title}
                </h1> */}
            </div>
            <FleetPage item={data?.data} />
          </>
        );

      case "multitab":
        return (
          <>
            <MultitabCommmon data={data?.data} />
          </>
        );
      case "embeddedForm":
        return (
          <>
            <div className="container">
              <h2 className="uppercase font-suzukiBold text-primary-blue text-heading mb-[50px]">
                {data && data?.data?.sectionTitle && data?.data?.sectionTitle}
              </h2>

              <EmbeddedForm data={data?.data} />
            </div>
          </>
        );
    }
  };
 let isTitlePresent = newData?.primary_details?.title ? true : false; 

 // google ad tag for sph starts after redirection after successfully filling the form 
// const router = useRouter()
const [showgtagScript, setShowgtagScript] = useState(false)

useEffect(() => {
  // Check if the "redirected" flag is present in the query parameters
  if (router.query.redirected === 'true') {
    setShowgtagScript(true)
  }
}, [router.query])
// google ad tag for sph starts after redirection after successfully filling the form 

  return (
    <div>
      <Head>
        <title>{propData?.seo_settings?.meta_title}</title>
        <meta name="description" content={propData?.seo_settings?.meta_desc} />
        <meta name="keywords" content={propData?.seo_settings?.keywords} />
        <meta name="keywords" content={propData?.seo_settings?.keywords} />
        {/* {console.log("email",user_email,"phone",user_email_phone)} */}
        {configuration.COUNTRY_CODE==="RO" && user_email && user_phone && <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'conversie',
                  'email': ${user_email},
                  'phoneNumber': ${user_phone}
                });
              `,
            }}
          />}
          {/* google ad tag after successfully converting the lead starts */}
          {showgtagScript && configuration?.COUNTRY_CODE=="PH"?(
          <script
            dangerouslySetInnerHTML={{
              __html: `
                // Google tag (gtag.js) event
                gtag('event', 'conversion_event_submit_lead_form', {
                  // event_parameters
                });
              `,
            }}
          />
        ):""}
        {/* google ad tag after successfully converting the lead ENDS */}
      </Head>
      {newData?.menu_id && newData?.menu_id != 0 && (
        <BookmarkMenu menuId={newData?.menu_id} menudata={props?.SubMenudata}/>
      )}
      <>
        <div className="mb-[95px]">
          {isBannerVisible && (
            <FinanceBanner
              head={newData?.primary_details?.banner_title}
              image={
                newData?.images?.desk_banner
                  ? configuration.IMG_URL + newData?.images?.desk_banner
                  : ""
              }
              imageMob={
                newData?.images?.mob_banner
                  ? configuration.IMG_URL + newData?.images?.mob_banner
                  : ""
              }
              video={newData.video ? configuration.IMG_URL + newData.video : ""}
              videoMob={
                newData?.mobile_video
                  ? configuration.IMG_URL + newData.mobile_video
                  : ""
              }
              isTitlePresent={isTitlePresent}
              alt_image={newData?.images?.desk_banner_alt}
              alt_imageMob={newData?.images?.mob_banner_alt}
              bannerClass="0"
              overlay={false}
              titleThemeData={titleTheme}
            />
          )}
        </div>
        {newData?.primary_details?.title_visibility
          ? newData?.primary_details?.title && (
              <div className="container-fluid mb-[100px]">
                <div className="container mt-10">
                  <h1 className="custom-testimonial-heading">
                    {newData?.primary_details?.title}
                  </h1>
                </div>
              </div>
            )
          : null}

        {newData?.primary_details?.long_desc && (
          <div
            className={`unreset my-10 sm:w-[90%] container ${
              !isBannerVisible && "mt-[100px]"
            } ${newData?.fleet?.order_index === null ? "lg:w-[50%]" : ""}`}
            dangerouslySetInnerHTML={{
              __html: newData?.primary_details?.long_desc,
            }}
          />
        )}
        {console.log("nnnn", newData)}
        {newData?.movableData?.map((item) => returnComponent(item))}

        {/* {newData?.multiTabs
          ?.sort((a, b) => a.order_index - b.order_index)
          ?.map((item) => (
            <div>
              <MultitabCommmon data={item} />
            </div>
          ))} */}

        {newData?.faqs?.length > 0 && (
          <div id="faqs" className="faq container-fluid">
            <div className="container">
              <div className="faq-page-wrapper">
                <div className="faq-slides-wrapper mb-[56px]">
                  <div className={styles.faq_grid_box}>
                    <Accordion
                      newArray={newData?.faqs?.map((item) => {
                        return {
                          question: item.ques,
                          answer: item.ans,
                        };
                      })}
                      heads={custServices.heads}
                      selectText={custServices.selectText}
                      openText={custServices.openText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      {newData?.primary_details?.tnc && (
          <div
          className="container unreset"
            // className={`unreset my-10 sm:w-[90%] container ${
            //   !isBannerVisible && "mt-[100px]"
            // } ${newData?.fleet?.order_index === null ? "lg:w-[50%]" : ""}`}
            dangerouslySetInnerHTML={{
              __html: newData?.primary_details?.tnc,
            }}
          />
        )}
    </div>
  );
}
