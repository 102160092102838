import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BookmarkMenuItem({ Bmenu }) {
  const [transparent, setTransparent] = useState(true);
  const [activeLink, setActiveLink] = useState(null);

  // function to set transparency of bookmark menu on scrolling
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setTransparent(false);
      } else {
        setTransparent(true);
      }
    });
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 100; // Adjust as needed
    if(Bmenu){
    for (const item of Bmenu) {
      const section = document?.querySelector(item.url);
      if (section && section instanceof HTMLElement) {
        const offsetTop = section.offsetTop;
        const offsetBottom = offsetTop + section?.offsetHeight;

        if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
          setActiveLink(item.url);
          break;
        }
      }
    }
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [Bmenu]);


  return (
    <>
      <nav>
        <ul className="flex justify-end mt-2 gap-5 font-suzukiBold flex-col md:flex-row">
          {Bmenu &&
            Bmenu.map((item, i) => {
              return (
                <li
                  key={i}
                  className={classNames(
                    transparent
                      ? "bk-menu"
                      : " bg-transparent" && transparent
                        ? "bk-menu"
                        : "hover:text-inverted hover:bg-primary-blue",
                    "text-small uppercase",
                  )}
                >
                  <AnchorLink
                    href={item.url}
                    onClick={() => handleLinkClick(item.url)}
                    className={classNames(
                      transparent ? "text-[#000]" : "border-t-0",
                      "px-3 py-[15px]  border-gray md:h-[10px] flex justify-center items-center font-suzukiBold",
                      // Check if it's active and add the "active" class
                      activeLink === `${item.url}`
                        ? "bg-primary-blue text-inverted font-suzukiBold "
                        : "",
                    )}
                  >
                    {item.text}
                  </AnchorLink>
                </li>
              );
            })}
        </ul>
      </nav>

    </>
  );
}
