import React, { useEffect, useRef, useState } from 'react';
import TopicSlide from "./topics/topicSlide";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import LinkButton from './button';
import useIsMobile from '../hooks/useIsMobile';
import { configuration } from '../helpers/config';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const SwiperSlider = ({ data, slidesPerView, breakpoints }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    } else {
      console.warn('swiperRef.current is not defined yet.');
    }
  };


  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.slideNext();
  };

  const renderContent = (item, id) => { 
    if (item.filename_pdf) {
      return (
        <SwiperSlide key={id} className="swiper-model">
          <div key={id}>
            <TopicSlide
              Id={`FourSilde${item?.title?.replace(/\s+/g, "")}`}
              form={item?.form_id}
              key={id}
              imgSrc={item?.image}
              alt_text={item.alt_text}
              filename_pdf={item?.filename_pdf}
              title={item?.title}
              cardDate={item?.cardDate}
              cardDescription={item?.desc}
            />
            {item?.cta_link && item?.cta_text && (
              <div className="cta-container">
                <LinkButton
                  Id={`FourSlidePDF${item?.title?.replace(/\s+/g, "")}`}
                  type="button_dark_arrow"
                  buttontext={item.cta_text}
                  target={item.target === "self" ? "" : "_blank"}
                  href={`${configuration.IMG_URL}${item.filename_pdf}`}
                />
              </div>
            )}
          </div>
        </SwiperSlide>
      );
    } else if (item.youtube_url) {
      return (
        <SwiperSlide key={id} className="swiper-model">
          <div className="video-container" key={id} style={{ borderRadius: "0.375rem", overflow: "hidden" }}>
            <ReactPlayer
              url={item.youtube_url}
              playing={false}
              controls={true}
              loop={true}
              volume={0}
              height="168px"
              width="300px"
              playsinline
              className="react-player"
            />
            <TopicSlide
              Id={`FourSilde${item?.title?.replace(/\s+/g, "")}`}
              form={item?.form_id}
              key={id}
              title={item?.title}
              cardDate={item?.cardDate}
              cardDescription={item?.desc}
            />
            {item?.cta_link && item?.cta_text && (
              <div className="cta-container">
                <LinkButton
                  Id={`FourSlideYouTube${item?.title?.replace(/\s+/g, "")}`}
                  type="button_dark_arrow"
                  buttontext={item.cta_text}
                  target={item.target === "self" ? "" : "_blank"}
                  href={item.youtube_url}
                />
              </div>
            )}
          </div>
        </SwiperSlide>
      );
    } else {
      return (
        <SwiperSlide key={id} className="swiper-model">
          <TopicSlide
            Id={`FourSilde${item?.title?.replace(/\s+/g, "")}`}
            form={item?.form_id}
            key={id}
            imgSrc={item?.image}
            alt_text={item?.alt_text}
            title={item?.title}
            cardDate={item?.cardDate}
            cta={{ isCta: true }}
            cardDescription={item?.desc}
            cardLink={item?.cta_link}
            cardBtnText={item?.cta_text}
            target={item?.target === "self" ? "" : "_blank"}
          />
        </SwiperSlide>
      );
    }
  };
  return (
    <Swiper
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
        handleSlideChange();
      }}
      onSlideChange={handleSlideChange}
      slidesPerView={slidesPerView}
      scrollbar={{ draggable: true }}
      freeMode={true}
      breakpoints={breakpoints}
    >
      {data
        ?.sort((a, b) => {
          const timestampA = parseInt(a._id.substring(0, 8), 16);
          const timestampB = parseInt(b._id.substring(0, 8), 16);
          return timestampB - timestampA;
        })
        .map((item, id) => renderContent(item, id))}

        {!isBeginning && (
          <div
            className="custom-left-arrow"
            onClick={handlePrev}
            aria-label="Previous Slide"
            tabIndex={0}
          >
            &#8249;
          </div>
        )}
        {!isEnd && (
          <div
            className="custom-right-arrow"
            onClick={handleNext}
            aria-label="Next Slide"
            tabIndex={0}
          >
            &#8250;
          </div>
        )}
    </Swiper>
  );
};

export default SwiperSlider;
