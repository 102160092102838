import Image from "next/image";
import Link from "next/link";
import useIsMobile from "../../hooks/useIsMobile";
import styles from "../../styles/error.module.css";
import { Constants } from "../../helpers/constants";

// Component used for Error Page
const ErrorBanner = (props) => {
  // Check if the user is on a mobile device
  const mobile = useIsMobile();

  return (
    <>
      <div className={styles.error_page_banner}>
        <div className={styles.error_page_img}>
          {/* Display the error page image based on the device type */}
          <Image
            src={mobile ? props.imageMob : props.image}
            height={700}
            w={1400}
            layout="fill"
          ></Image>
        </div>

        <div className={styles.error_page_content}>
          {/* Display the error message */}
          <h1 className={styles.error_page_head}>{props.error}</h1>
          {/* Display a generic error description */}
          <p className={styles.error_page_desc}>
            {Constants.generic.Four0Four}
          </p>
          {/* Provide a link to the home page */}
          <Link href="/">
            <a className={styles.error_page_btn}>{props.btn}</a>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ErrorBanner;
